<template>
  <div class="freelancePaymentDetailBody">
    <PocCardTable class="freelancePaymentDetail">
<!--      <template v-slot:headerTitle>Freelance Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">Freelance Repo</div>
          <div>
            <el-button @click="search" type="primary">查询</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="goback">返回</el-button>
          </div>
        </div>
      </template>
      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="成本中心">
            <el-select
              v-model="formInline.costCenter"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in costCenters"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="姓名" label-position="top">
            <el-input placeholder="" v-model="formInline.name"> </el-input>
          </el-form-item>
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable style="margin-top: 20px">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="maxHeight"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { freelancePaymentDetailTableColumn } from '@/views/payroll/TableColumn'
import {
  getPayrollFreeContDetailList,
  getPayrollCostCenterList
} from '@/api/payroll'
import {freeContExport} from '@/api/payrollReport'
import PocSlider from '@/components/poc-slider/poc-slider'

const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: freelancePaymentDetailTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        periodUploadRecord: '',
        periodFrom: '',
        periodTo: '',
        costCenter: '',
        name: '',
        pageSize: 10,
        pageNum: 1
      },
      costCenters: [],
      targetObj: '',
      toObj: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    let uploadRecordId = this.$route.query.id

    if (uploadRecordId) {
      this.formInline.periodUploadRecord = uploadRecordId
    }

    this.maxHeight = h - 330
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getPayrollCostCenterList().then((res) => {
        this.costCenters = res.data
      })
    },

    search() {
      getPayrollFreeContDetailList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
      })
    },

    queryApi() {
      this.search()
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    download() {
      freeContExport(this.formInline)
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll',
        query: {
          id: 'freelancePayment',
          title: 'freelancePayment'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.freelancePaymentDetailBody {
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .freelancePaymentDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
